.root {
    position: relative;
    /* background: rgba(189, 187, 187, 0.53); */
    background-color: rgba(189, 187, 187, 0.53);
    background-image: url("../../assets/usersend.png") no-repeat center;
    width: 400px;
    height: 500px;
    padding-Left: "150px";
    padding-Right: "150px";
    /* background-size:20%; */
}

.backimg {
    background: url("../../assets/usersend.png") no-repeat center;
    background-size: 20%;
}

.nobackimg {
    text-align: center;
}

.zoom {
    width: 100%;
    height: 100%;
}

.screen {
    width: 100%;
    height: 100%;
}

.root>div {
    width: 100%;
    height: 100%;
    z-index: 0;
}

.root>div:nth-child(1) {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}

.root>div:nth-child(2) {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 40%;
    height: 40%;
}

.root>div:nth-child(3) {
    display: none;
}

.userName {
    left: 0;
    width: 100%;
    position: absolute;
    text-align: center;
}

.ccccs {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    color: white;
}