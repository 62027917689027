body {
  margin: 0;
  padding: 0;
  overflow: hidden;
  background-color: #212121;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.niu {
  position: absolute;
  top: 5%;
  right: -8%;
  z-index: -1;
}

.home_btn {
  margin-top: 33px !important;
  width: 500px;
  height: 55px;
  background: #34AADC !important;
  border-radius: 100px !important;
  font-size: 18px;
  color: #FAFAFA !important;
  letter-spacing: 0.85px;
  outline: none !important;
  border: none !important;
  text-align: center;
  cursor: pointer;
  z-index: 10;
  position: relative;
}
